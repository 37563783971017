<template>
  <div class="product">
    <div class="flex align-center justify-between mg-b-15">
      <div class="flex align-center">
        <h2 class="mg-r-15">{{$t('orderlist.management')}}</h2>
      </div>
    </div>
    <form @submit.prevent="searchItems" class="product__search flex align-center justify-start mg-b-15 mg-t-15 pd-15">
      <div class="form-element mg-r-15">
        <input type="text" v-model="query" :placeholder="$t('global.search')" class="form-control"/>
      </div>
      <label for="status">{{$t('orderlist.search_status')}}</label>
      <div class="form-element mg-r-15 mg-l-15">
        <select v-model="query_status" id="status" class="form-control">
          <option value="1">{{ $t('orderlist.open') }}</option>
          <option value="2">{{ $t('orderlist.completed') }}</option>
          <option value="3">{{ $t('orderlist.refunded') }}</option>
        </select>
      </div>
      <label for="type">{{$t('orderlist.search_type')}}</label>
      <div class="form-element mg-r-15 mg-l-15">
        <select v-model="query_product_type" id="type" class="form-control">
          <option v-for="(item, index) in productType" :key="index" :value="index">{{ $t(productType[index]) }}</option>
        </select>
      </div>
      <button type="submit" class="btn btn--red">{{$t('global.search_btn')}}</button>
      <button type="button" class="btn btn--green mg-l-15" @click.prevent="resetSearch">{{$t('global.reset_btn')}}</button>
    </form>
    <div class="table">
      <div class="table__head">
        <div class="w-50 flex align-center justify-start">
          <Checkbox :checked="checkAll" @change="toggleCheckbox"/>
          {{$t('global.no')}}
        </div>
        <div class="w-150">{{$t('orderlist.transaction')}}</div>
        <div class="w-200">{{$t('orderlist.number')}}</div>
        <div class="w-125">{{$t('orderlist.status')}}</div>
        <div class="w-150">{{$t('orderlist.date')}}</div>
        <div class="w-125">{{$t('orderlist.type')}}</div>
        <div class="w-200">{{$t('orderlist.name')}}</div>
        <div class="w-200">{{$t('orderlist.promo_type')}}</div>
        <div class="w-125">{{$t('orderlist.quantity')}}</div>
        <div class="w-125">{{$t('orderlist.amount')}}</div>
        <div class="w-150">{{$t('orderlist.fullfillment')}}</div>
        <div class="w-350">{{$t('orderlist.merchant_notes')}}</div>
        <div class="w-50">{{$t('global.actions')}}</div>
      </div>
      <div v-for="(item, index) in list" :key="index" class="table__line">
        <div class="w-50 flex align-center justify-start">
          <Checkbox :checked="item.selected" @change="list[index].selected = !list[index].selected"/>
          {{ index + 1 }}
        </div>
        <div class="w-150">{{item.order.system_id}}</div>
        <div class="w-200">{{item.system_id}}</div>
        <div class="w-125 flex align-center justify-start">
          <div class="dot mg-r-10" :class="{
            error: item.status === 3,
            warning: item.status === 1,
            success: item.status === 2
          }"/>
          <div>{{item.status === 1 ? $t('orderlist.open') : (item.status === 2 ? $t('orderlist.completed') : $t('orderlist.refunded'))}}</div>
        </div>
        <div class="w-150">{{$filters.formatDate(item.order.created_at)}}</div>
        <div class="w-125">
          <div>
            {{$t(productType[item.product_snapshot.type])}}
          </div>
        </div>
        <div class="w-200">
          <div>
            {{item.product_snapshot.name}}
          </div>
          <div class="red">
            {{item.product_snapshot.name_c}}
          </div>
        </div>
        <div class="w-200">
          <div>
            {{item.product_snapshot.promotion_type ? $t(promoTypes[item.product_snapshot.promotion_type]) : '-'}}
          </div>
        </div>
        <div class="w-125">{{item.quantity}}</div>
        <div class="w-125 green">{{$filters.formatPrice(item.quantity * (item.product_snapshot.type === 3 ? item.product_snapshot.negotiated_price : item.unit_cost))}}</div>
        <div class="w-150">
          <select-input :reverseOptions="index === list.length - 1" :value="{
            label: item.status === 1 ? $t('orderlist.open') : (item.status === 2 ? $t('orderlist.completed') : $t('orderlist.refunded')),
            value: item.status
          }" :disabled="item.status === 3" @select="(data) => onStatusChange(index, data)" label-key="label" value-key="value" :options="[{ label: $t('orderlist.open'), value: 1 }, { label: $t('orderlist.completed'), value: 2 }, { label: $t('orderlist.refunded'), value: 3 }]"/>
        </div>
        <div class="w-350 flex align-center justify-start">
          <div class="flex-80 form-element">
            <input type="text" v-model="item.merchant_comments" class="form-control"/>
          </div>
          <button class="flex-20 mg-l-10 mg-r-20 btn btn--red round" @click="saveComments(item.id, item.merchant_comments)">{{$t('global.save')}}</button>
        </div>
        <div class="actions w-50">
          <router-link :to="`orders/${item.order.id}-${item.id}`" class="btn btn--orange" target="_blank"><i class="fas fa-eye"/></router-link>
        </div>
      </div>
    </div>
    <div class="flex justify-center mg-t-20" v-if="hasMore">
      <button class="btn btn--red" @click.prevent="loadOrders">{{$t('orderlist.load_more')}}</button>
    </div>
    <Loading v-if="isLoading"/>
    <refund :transid="refundTransId" :refundId="refundId" :max="refundMax" ref="refundModal" @onRefund="onRefund"/>
  </div>
</template>

<script>

import Checkbox from '@/components/checkbox/checkbox'
import Select from '@/components/select/select'
import Loading from '@/components/loading/Loading'
import { mapGetters } from 'vuex'
import { Orders } from '@/api/api'
import Refund from '@/components/refund/refund'
import { promoTypes, productType } from '@/config/config'

export default {
  name: 'OrderList',
  components: {
    Refund,
    SelectInput: Select,
    Loading,
    Checkbox
  },
  data () {
    return {
      isLoading: true,
      page: 1,
      query: '',
      query_status: '',
      query_product_type: '',
      hasMore: true,
      refundMax: 0,
      refundId: 0,
      refundIndex: -1,
      checkAll: false,
      refundTransId: 0,
      list: []
    }
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed',
      me: 'auth/me'
    }),
    promoTypes () {
      return promoTypes
    },
    productType () {
      return productType
    }
  },
  mounted () {
    this.loadOrders()
  },
  methods: {
    toggleCheckbox () {
      this.list = this.list.map((i) => {
        i.selected = !this.checkAll

        return i
      })

      this.checkAll = !this.checkAll
    },
    formatTotalPrice (item) {
      return (item.quantity * item.unit_cost).toFixed(2)
    },
    searchItems () {
      this.page = 1
      this.list = []

      this.loadOrders()
    },
    resetSearch () {
      this.query = null
      this.query_status = null
      this.query_product_type = null
      this.page = 1
      this.list = []
      this.loadOrders()
    },
    async loadOrders () {
      this.isLoading = true

      try {
        const resp = await Orders.merchantAll({
          page: this.page,
          search_term: this.query,
          status_id: this.query_status,
          product_type: this.query_product_type
        })

        const list = [...resp.data.data]

        if (list.length >= resp.data.total) {
          this.hasMore = false
        }

        this.page = this.page + 1
        this.list = [...list]
      } catch (err) {
        this.$toast.error(this.$t('orderlist.load_fail'))
      } finally {
        this.isLoading = false
      }
    },
    async onStatusChange (index, val) {
      if (val.value === 3) {
        this.openRefund(index, this.list[index])

        return
      }

      this.isLoading = true

      try {
        await Orders.statusChange(this.list[index].id, val.value)

        this.list[index].status = val.value
      } catch (err) {
        this.$toast.error(this.$t('orderlist.status_update_fail'))
      } finally {
        this.isLoading = false
      }
    },
    onRefund (data) {
      this.list[this.refundIndex].status = 3
      this.list[this.refundIndex].refund_amount = data.refund
      this.list[this.refundIndex].refund_reason = data.reason
      this.$refs.refundModal.toggle()
    },
    openRefund (index, item) {
      this.refundTransId = item.order.trans_id
      this.refundId = item.id
      this.refundIndex = index
      this.refundMax = item.quantity * (item.product_snapshot.type === 3 ? item.product_snapshot.negotiated_price : item.unit_cost)

      this.$refs.refundModal.toggle()
    },
    async saveComments (id, comments) {
      this.isLoading = true

      try {
        await Orders.merchantComment(id, comments)

        this.$toast.success(this.$t('orderlist.comments_saved'))
      } catch (err) {
        this.$toast.error(this.$t('orderlist.comments_failed'))
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>
